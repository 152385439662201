import styled from "@emotion/styled";
import { Trans } from "@lingui/macro";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { motion } from "framer-motion";
import LandingLayout from "../../components/landings/LandingLayout";
import CardColor from "../../components/CardColor";
import Container from "../../components/Container";
import SecondaryButton from "../../components/SecondaryButton";
import HeroLanding from "../../components/HeroLanding";
import ProductModal from "../../components/ProductModal";
import buildInvesmentImg from "../../images/esg/build-sustainability-banner.png";
import secondImage from "../../images/esg/check-key-sustainable.png";
import sustainabilityAssementReport from "../../images/esg/home/sustainability-assement-report.png";
import blueIcon from "../../images/icons/blue.png";
import fileImageGreen from "../../images/icons/rr-green-02.png";
import yellowIcon from "../../images/icons/yellow.png";
import banner from "../../images/esg/sustainability-banner.png";
import { theme } from "../../themeV2";

const metadata = {
  title: "Sustainability Navigator",
  description: "Sustainability Navigator is the ultimate platform solution, providing the complete toolkit to build, analyse and optimize sustainable portfolios, in line with current EU regulatory frameworks.",
};

const CHECK_ITEMS = [
  {
    title: <Trans>Upload your portfolio</Trans>,
    description: <Trans>Upload your portfolio composition in one click.</Trans>,
  },
  {
    title: <Trans>One-Click Analysis</Trans>,
    description: <Trans>Check main sustainable features in seconds.</Trans>,
  },
];

const BUILD_ITEMS = [
  {
    title: <Trans>Define</Trans>,
    description: (
      <Trans>
        Define your sustainable strategy following a SFDR framework.
      </Trans>
    ),
  },
  {
    title: <Trans>Create</Trans>,
    description: (
      <Trans>
        Develop a sustainable fund portfolio that meets your specific
        objectives.
      </Trans>
    ),
  },
  {
    title: <Trans>Analyse</Trans>,
    description: (
      <Trans>
        Gain actionable insights that optimize your fund’s sustainable features.
      </Trans>
    ),
  },
];

const ITEMS = [
  {
    icon: fileImageGreen,
    color: theme.palette.colors.green.main,
    title: <Trans>Launch or reclassify an art. 8 or 9 fund</Trans>,
    description: (
      <Trans>
        Effortlessly launch or upgrade your funds to Art. 8 or 9 status with our intuitive platform.
      </Trans>
    ),
  },
  {
    icon: yellowIcon,
    color: theme.palette.colors.yellow.main,
    title: (
      <Trans>
        Transform ESG and Sustainability analysis into an in-depth report
      </Trans>
    ),
    description: (
      <Trans>
        Generate comprehensive ESG reports that deliver actionable insights for your portfolios.
      </Trans>
    ),
  },
  {
    icon: blueIcon,
    color: theme.palette.colors.blue.chart,
    title: <Trans>Gain key insights for existing art. 8 or 9 funds</Trans>,
    description: (
      <Trans>
        Analyze your current funds for critical insights that can enhance your sustainable investment strategy.
      </Trans>
    ),
  },
];

const getAnimationProps = (direction) => ({
  initial: "hidden",
  whileInView: "visible",
  viewport: { once: true, amount: 0.8 },
  transition: { duration: 0.8 },
  variants: {
    hidden: { opacity: 0, y: direction === "down" ? 70 : -70 },
    visible: { opacity: 1, y: 0 },
  },
});

function AnimatedDiv({ isMobile, direction, children }) {
  if (isMobile) {
    return <div>{children}</div>;
  }
  return <motion.div {...getAnimationProps(direction)}>{children}</motion.div>;
}

function EsgHome() {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const [showModal, setShowModal] = useState(false);
  const [hoveredCard, setHoveredCard] = useState(null);
  const [subTitle, setSubTitle] = useState(null);

  const handleOnclickInfo = (key) => {
    setSubTitle(key);
    setShowModal(true);
  };

  const renderRequestButton = (key) => {
    return (
      <SecondaryButton
        handleOnclickInfo={() => handleOnclickInfo(key)}
        text={<Trans>Request info</Trans>}
      />
    );
  };

  const renderCard = ({ color, icon, title, description }) => {
    return (
      <CardColor color={color} icon={icon} title={title} description={description} />
    );
  };

  const buildItemsCheck = () => {
  return (
    <Stack pt={{xs: 3, sm: 4, md: 5.5}} spacing={{ xs: 4.5, sm: 1}} direction={{ xs: "column", sm: "row" }}>
      {CHECK_ITEMS.map(({ title, description }, index) => (
        <Stack direction="row" spacing={1}>
          <StyledBox>
            <Typography variant="text" color={theme.palette.colors.green.dark}>
              {index + 1}
            </Typography>
          </StyledBox>
          <Stack spacing={0.5}>
            <Typography variant="heading">{title}</Typography>
            <Typography variant="small" color={theme.palette.colors.blue.light}>
              {description}
            </Typography>
          </Stack>
        </Stack>
    ))}
    </Stack>
  );
  };

  return (
    <LandingLayout seoTitle={metadata.title} seoDescription={metadata.description}>
      <HeroLanding
        title={
          <Trans>Streamline Sustainable Portfolio Creation and Reporting</Trans>
        }
        description={
          <Trans>
            Sustainability Navigator serves as the ultimate platform solution,
            providing the complete toolkit to build, analyse and optimize sustainable portfolios,
            in line with current EU regulatory frameworks.
          </Trans>
        }
        handleOnclickInfo={() => handleOnclickInfo("Sustainability Navigator")}
        multimedia={banner}
        grid={{
          columnLeft: { md: 7 },
          columnRight: { md: 5 },
        }}
        kind="Sustainability Navigator"
        isImageToBorder={true}
        kindColor={theme.palette.colors.green.dark}
      />
      <Box py={{ xs: 6.5, sm: 7.5 }} sx={{backgroundColor: theme.palette.colors.grey.light}}>
        <Container>
          <Grid spacing={{ xs: 1.5, sm: 4, md: 9.5 }} container alignItems="center">
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <Typography variant="text" color={theme.palette.colors.green.dark}>
                  Sustainability Navigator
                </Typography>
                <Stack spacing={2}>
                  <Typography variant="h2">
                    <Trans>How Sustainable is</Trans>
                  </Typography>
                  <Typography variant="h2" sx={{ mt: "0 !important"}}>
                    <Trans>Your Portfolio?</Trans>
                  </Typography>
                  <Stack spacing={1}>
                    <Typography color={theme.palette.colors.blue.light}>
                      <Trans>
                        Sustainability Navigator is one stop-shop to check, build and
                        automate the calculation of sustainability features and
                        reporting for Art.8 and Art.9 SFDR funds.
                      </Trans>
                    </Typography>
                    <Typography color={theme.palette.colors.blue.light}>
                      <Trans>
                        It is the ultimate platform solution crafted by our ESG expert
                        team, backed by over 15 years of industry experience.
                      </Trans>
                    </Typography>
                  </Stack>
                  <Box display="flex" justifyContent={{ xs: "center", md: "flex-start" }}>
                    {renderRequestButton("Sustainability Navigator")}
                  </Box>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                >
                <Grid item width="100%" xs={12} sm={6} sx={{ paddingRight: { xs: 0, sm: 1 } }}>
                  <AnimatedDiv isMobile={isMobile} direction="down">
                    {ITEMS.slice(0, 2).map((item) => (
                        renderCard({ ...item, isHovered: hoveredCard, setIsHovered: setHoveredCard })
                      ))}
                  </AnimatedDiv>
                </Grid>
                <Grid item width="100%" xs={12} sm={6} sx={{ paddingLeft: { xs: 0, sm: 1 } }}>
                  <AnimatedDiv isMobile={isMobile} direction="up">
                    {renderCard({ ..._.last(ITEMS), isHovered: hoveredCard, setIsHovered: setHoveredCard })}
                  </AnimatedDiv>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container pt={{ xs: 2, sm: 6.5, md: 10 }} pb={{ xs: 4, sm: 2.5, md: 8 }}>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} lg={8.5}>
            <Stack spacing={1} textAlign="center">
              <Typography variant="text" color={theme.palette.colors.green.dark}>
                Build
              </Typography>
              <Stack spacing={2} textAlign="center">
                <Typography variant="h2" lineHeight={1}>
                  <Trans>
                    Build your Sustainable
                  </Trans>
                  <br />
                  <Trans>
                    Investment Universe or Portfolio
                  </Trans>
                </Typography>
                <Typography color={theme.palette.colors.blue.light}>
                  <Trans>
                    Build from scratch using an exclusive SFDR framework designed
                    by
                  </Trans>
                  <br />
                  <Trans color={theme.palette.colors.blue.light}>
                    our ESG expert team aligned with the regulation.
                  </Trans>
                </Typography>
                <Grid justifyContent="center">
                  <Grid container spacing={{ xs: 0, sm: 4 }} xs={12} sm={8} md={12}>
                    {BUILD_ITEMS.map(({ title, description }, index) => (
                      <Grid item xs={12} md={4} pt={{ xs: index > 0 ? 3 : 0 }}>
                        <Stack direction="row" spacing={1} textAlign="left">
                          <StyledBox>
                            <Typography variant="text" color={theme.palette.colors.green.dark}>
                              {index + 1}
                            </Typography>
                          </StyledBox>
                          <Stack spacing={0.5}>
                            <Typography variant="heading">{title}</Typography>
                            <Typography variant="small" color={theme.palette.colors.blue.light}>
                              {description}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                ))}
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
            <Box pt={4.5}>
              <img width="100%" alt="first" src={buildInvesmentImg} />
              {(isDownMd) && (
              <Box pt={3.5} display="flex" justifyContent={{ xs: "center", md: "flex-start" }}>
                {renderRequestButton("Sustainability Navigator")}
              </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box pt={{ xs: 8, md: 14 }} pb={{ xs: 5, md: 11.5 }} sx={{ overflow: "hidden", position: "relative", backgroundColor: theme.palette.colors.grey.light }}>
        <Container>
          <Grid
            container
            spacing={4}
            alignItems="center"
        >
            <Grid item xs={12} md={5}>
              <Stack spacing={1}>
                <Typography variant="text" color={theme.palette.colors.green.dark}>
                  Check
                </Typography>
                <Stack spacing={2}>
                  <Typography variant="h2">
                    <Trans>Check Key Sustainable Insights of Your Portfolio</Trans>
                  </Typography>
                  <Stack spacing={1}>
                    <Typography color={theme.palette.colors.blue.light}>
                      <Trans>
                        Check the portfolio of an existing fund or simulate a
                        portfolio.
                      </Trans>
                    </Typography>
                    <Typography color={theme.palette.colors.blue.light}>
                      <Trans>
                        Using an exclusive SFDR framework designed by our
                        ESG expert team aligned with the regulation.
                      </Trans>
                    </Typography>
                  </Stack>
                  {(!isDownMd) && renderRequestButton("Sustainability Navigator")}
                </Stack>
              </Stack>
              {(!isDownMd) && buildItemsCheck()}
            </Grid>
            <GridOuside item xs={12} md={7}>
              <img width="100%" alt="second" src={secondImage} />
            </GridOuside>
          </Grid>
          {(isDownMd) && buildItemsCheck()}
          {(isDownMd) && (
          <Box pt={{xs: 3, sm: 5.7}} display="flex" justifyContent={{ xs: "center", md: "flex-start" }}>
            {renderRequestButton("Sustainability Navigator")}
          </Box>
          )}
        </Container>
      </Box>
      <Container pt={{ xs: 8, md: 4 }} pb={{ xs: 5, md: 8 }}>
        <Grid container spacing={4} alignItems="center" flexDirection={{ xs: "column-reverse", md: "row" }}>
          <Grid item xs={12} md={7} pt={{ xs: "106px !important", md: 0 }} justifyContent={{ xs: "center", md: "flex-start" }}>
            <img width="100%" alt="third" src={sustainabilityAssementReport} />
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack
              spacing={1}
            >
              <Typography variant="text" color={theme.palette.colors.green.dark}>
                Sustainable Report
              </Typography>
              <Stack
                spacing={2}
                sx={{
                "> ul": {
                  color: `${theme.palette.colors.blue.light} !important`,
                },
              }}
            >
                <Typography fontWeight={600} fontSize={36} lineHeight={1}>
                  <Trans>Sustainability Assessment Report</Trans>
                </Typography>
                <Typography color={theme.palette.colors.blue.light}>
                  <Trans>
                    Support your sustainable strategy and selection with an
                    in-depth analysis
                  </Trans>
                </Typography>
                <ul>
                  <li>
                    <Trans>Percentage of Sustainable Investments</Trans>
                  </li>
                  <li>
                    <Trans>ESG ratings</Trans>
                  </li>
                  <li>
                    <Trans>Sustainable Development Goals (SDGs)</Trans>
                  </li>
                  <li>
                    <Trans>Controversial activities</Trans>
                  </li>
                </ul>
                <Box display="flex" justifyContent={{ xs: "center", md: "flex-start" }}>
                  {renderRequestButton("Sustainability Assessment Report")}
                </Box>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      {showModal && (
        <ProductModal
          onClose={() => setShowModal(false)}
          product="sustainability-navigator"
          subject="ESG - Sustainability Navigator"
          hasTemplate={false}
          subtitle={subTitle}
        />
      )}
    </LandingLayout>
  );
}

const StyledBox = styled(Box)`
  width: 42px;
  height: 42px;
  min-width: 42px;
  display: grid;
  border-radius: 50%;
  place-content: center;
  background-color: rgba(14, 143, 97, 0.2);
`;

const GridOuside = styled(Grid)`
  @media (min-width: 900px) and (max-width: 1440px) {
    position: absolute;
    right: -100px;
    padding-left: 0 !important;
  }
`;

export default EsgHome;
